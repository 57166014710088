<template>
  <div class="wxpay">
    <pay :courseVO="courseVO" v-on:on-pay="onPay" :payReady="isWxCodeReady"></pay>
    <csWidget v-if="showCsWidget" :csId="'1'"></csWidget>
  </div>
</template>

<script>
const QUERY_INTERVAL = 5000;

import pay from "@/components/pay.vue";
import csWidget from "@/components/csWidget.vue";
import { Notify } from "vant";
import { payApi } from "@/api/payApi.js";
import { orderApi } from "@/api/orderApi.js";
import { courseApi } from "@/api/courseApi.js";
import { wechatInitApi } from "@/api/wechatInitApi.js";

export default {
  components: { pay, csWidget },
  data() {
    return {
      courseId: 0,
      orderId: 0,
      courseInfo: {},
      codeUrl: "",
      courseVO: {
        course: {
          scheduleCnt: 0,
          saleCount: 0,
          showPrice: 0,
          truePrice: 0,
          tags: [],
        },
        scheduleList: [],
        ownThisCourse: 0,
        teacherList: [],
      },
      clock: null,
      openId: "",
      showCsWidget: false,
      csTitle: "参数出错，请联系客服",
      amount: 0,
      isWxCodeReady: false,
    };
  },
  methods: {
    //https://developers.weixin.qq.com/doc/offiaccount/OA_Web_Apps/Wechat_webpage_authorization.html
    getBaseInfos() {
      const currentUrl = window.location.href;
      const url = encodeURIComponent(currentUrl);
      const urlWithCode =
        "https://open.weixin.qq.com/connect/oauth2/authorize?appid=" +
        payApi.AppID +
        "&redirect_uri=" +
        url +
        "&response_type=code&scope=snsapi_base&state=1&connect_redirect=1#wechat_redirect";
      window.location.href = urlWithCode; // 打开这个链接，你的url后面就会跟上code的参数
    },
    getCode() {
      this.isWxCodeReady = false;
      if (this.$route.query && this.$route.query.code) {
        const code = this.$route.query.code;
        this.isWxCodeReady = true;
        wechatInitApi
          .getOpenId(code)
          .then((ret) => {
            console.log("openid", ret);
            if (ret.code == 0 && ret.data) {
              this.openId = ret.data.openid;
            }
          })
          .catch((err) => {
            this.showCsWidget = true;
            this.csTitle = "获取微信参数出错";
          });
      } else {
        this.getBaseInfos();
      }
    },
    onPay(orderDiscountVO) {
      if (!this.courseId || !this.openId) {
        this.showCsWidget = true;
        return;
      }
      const _this = this;
      this.amount = this.courseInfo.truePrice;
      if (orderDiscountVO && orderDiscountVO.totalFee > 0) {
        this.amount = orderDiscountVO.totalFee;
      }
      console.log("amount", orderDiscountVO.totalFee);

      let jsApiParameters = {};
      const onBridgeReady = function() {
        window.WeixinJSBridge.invoke("getBrandWCPayRequest", jsApiParameters, (res) => {
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            _this.onQueryPayment();
            _this.clock = setInterval(() => {
              _this.onQueryPayment();
            }, 5000);
          }
          if (res.err_msg === "get_brand_wcpay_request:cancel" || res.err_msg === "get_brand_wcpay_request:fail") {
            alert("取消支付");
            // window.location.reload()
          }
        });
      };
      const callpay = function() {
        if (typeof window.WeixinJSBridge === "undefined") {
          if (document.addEventListener) {
            document.addEventListener("WeixinJSBridgeReady", onBridgeReady, false);
          } else if (document.attachEvent) {
            document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
            document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
          }
        } else {
          onBridgeReady();
        }
      };
      orderApi.create(this.courseId, orderDiscountVO.referPhone, orderDiscountVO.reducePriceLong).then((ret) => {
        const { code, msg, data } = ret;
        if (code !== 0) {
          alert(msg);
        }
        this.orderId = data.id;
        console.log(this.orderId);
        payApi
          .startPay(this.openId, "WXPAY_MP", this.orderId, this.amount)
          .then((ret) => {
            jsApiParameters = ret.data;
            callpay();
          })
          .catch((err) => {
            alert(err);
          });
      });
    },
    getId() {
      if (this.$route.query && this.$route.query.id) {
        this.courseId = this.$route.query.id;
      }
      if (this.courseId <= 0) {
        this.showCsWidget = true;
      }
      return this.courseId;
    },
    getCourse(courseId) {
      courseApi.courseInfo(courseId).then((ret) => {
        if (ret && ret.code == 0 && ret.data) {
          this.courseInfo = ret.data.course;
          this.courseVO = ret.data;
          console.log("courseVO...", this.courseVO);
        } else {
          this.showCsWidget = true;
        }
      });
    },
    onQueryPayment() {
      if (this.orderId <= 0) {
        console.log("order id <= 0", this.orderId);
        return;
      }
      orderApi
        .queryOrderPayment(this.orderId)
        .then((ret) => {
          const { code, msg, data } = ret;
          console.log("..", data);
          if (code === 0 && data) {
            if (data.status === 1) {
              // 已支付
              clearInterval(this.clock);
              this.$router.push({
                path: "/pay/payWaiting",
                query: {
                  id: this.orderId,
                },
              });
            }
          } else {
            this.showCsWidget = true;
          }
        })
        .catch((err) => {
          this.showCsWidget = true;
        });
    },
    listenOnCloseCsWidget() {
      this.$EventBus.$on("onCloseCsWidget", (val) => {
        this.showCsWidget = false;
      });
    },
  },
  mounted() {
    const courseId = this.getId();
    this.courseId = courseId;
    this.listenOnCloseCsWidget();
    this.getCode();
    this.getCourse(courseId);
    this.$once("hook:beforeDestory", function() {
      clearInterval(this.clock);
      this.clock = null;
    });
  },
  beforeDestroy() {
    clearInterval(this.clock);
    this.clock = null;
  },
  watch: {},
};
</script>

<style lang="less" scoped>
.wxpay {
  display: flex;
  flex-direction: column;
  background: rgb(245, 246, 248);
  height: 100%;
}
</style>
