import { Notify } from "vant";
import ajax from "./ajax.js";

let base = process.env.VUE_APP_BASE_URL;

const getOpenId = (code) => {
  return ajax.get(`${base}/api/wechat-openid?code=${code}`);
};

const getOpenIdUserInfo = (code) => {
  return ajax.get(`${base}/api/wechat-openid-userinfo?code=${code}`);
};

const getJsApiSignature = (url) => {
  return ajax.get(`${base}/api/wechat-jsapi-signature?url=${url}`);
};

const getOpenLink = (id) => {
  return ajax.get(`${base}/api/itedu/v1/wechat/mp-openlink?id=${id}`);
};

export const wechatInitApi = {
  // import时得花括号明确
  getOpenId: getOpenId,
  getOpenIdUserInfo: getOpenIdUserInfo,
  getJsApiSignature: getJsApiSignature,
  getOpenLink: getOpenLink,
};
